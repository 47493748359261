/* eslint-disable no-unused-vars */
import i18next from "i18next";
import { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import api from "../services/api";

/**
 * This hook returns the correct territorial grouping based on the current territory type.
 * @param {The current territory} territory
 */
export default function useGrouping(
  territory,
  dataType,
  paramsLoaded,
  groupingOptions,
  initiative,
  hasntFirstTerritory
) {
  const [groups, setGroups] = useState(groupingOptions);
  const [selectedGroup, setSelectedGroup] = useState();
  const [defaultGroupingStatic, setDefaultGroupingStatic] = useState();
  const [hierarchy, setHierarchy] = useState();
  const history = useHistory();

  useEffect(() => {
    if (defaultGroupingStatic) {
      setSelectedGroup(defaultGroupingStatic);
    }
  }, [dataType, defaultGroupingStatic]);

  function reset() {
    let isSubscribed = true;

    if (territory) {
      api.get(`initiatives`).then((initiativesResp) => {
        if (isSubscribed) {
          api
            .get(`territories/${territory.type}/${territory.code}/groupings`, {
              params: { dataType, initiative },
            })
            .then(({ data }) => {
              if (isSubscribed) {
                const groupsAux = {};

                Object.keys(data).forEach((key) => {
                  groupsAux[key] = {
                    translation: `dashboard.filters.grouping.${key}`,
                  };

                  i18next.addResource(
                    "pt",
                    "translation",
                    `dashboard.filters.grouping.${key}`,
                    data[key].pt
                  );
                  i18next.addResource(
                    "en",
                    "translation",
                    `dashboard.filters.grouping.${key}`,
                    data[key].en
                  );
                  i18next.addResource(
                    "es",
                    "translation",
                    `dashboard.filters.grouping.${key}`,
                    data[key].es
                  );
                });

                setGroups(groupsAux);

                if (
                  (!paramsLoaded && !selectedGroup) ||
                  paramsLoaded ||
                  hasntFirstTerritory
                ) {
                  const foundDefault = initiativesResp.data.find(
                    (e) => e.type === initiative
                  )?.defaultGrouping;

                  if (groupsAux[foundDefault]) {
                    setSelectedGroup(foundDefault);
                    setDefaultGroupingStatic(foundDefault);
                  } else {
                    const firstGroupKey = Object.keys(groupsAux)[0];
                    setSelectedGroup(firstGroupKey);
                    setDefaultGroupingStatic(firstGroupKey);
                  }
                }
              }
            });

          api.get(`territories/translations`).then(({ data }) => {
            if (isSubscribed) {
              const hierarchyAux = [];

              Object.keys(data).forEach((key) => {
                hierarchyAux.push({ position: data[key].hierarchy, type: key });
              });

              setHierarchy(hierarchyAux);
            }
          });
        }
      });
    }

    return () => {
      isSubscribed = false;
    };
  }

  /**
   * Generate groups options to the selected territory.
   */
  useEffect(() => {
    if (territory) {
      reset();
    }
  }, [territory, initiative, dataType]);

  return [groups, hierarchy, selectedGroup, setSelectedGroup, reset];
}
