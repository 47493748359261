/* eslint-disable no-unused-vars */
import React, {
  createContext,
  useContext,
  useEffect,
  useState,
  useMemo,
} from "react";
import PropTypes from "prop-types";
import i18next from "i18next";
import { useParams } from "react-router-dom";
import { options, defaults } from "../constants/general";
import api from "../services/api";
import useGrouping from "../hooks/grouping";
import LocationContext from "./location";
import useYears from "../hooks/years";
import useMonths from "../hooks/months";

const FiltersContext = createContext({});

/**
 * The FiltersProvider is context to provide options to filters.
 * */
export function FiltersProvider({ children }) {
  FiltersProvider.propTypes = {
    children: PropTypes.oneOfType([
      PropTypes.arrayOf(PropTypes.node),
      PropTypes.node,
    ]).isRequired,
  };

  const params = useParams();
  const { handleFiltersRoute, dataType } = useContext(LocationContext);

  const [territoriesOptions, setTerritoriesOptions] = useState([]);
  const [initiative, setInitiative] = useState(
    params.initiative || defaults[dataType].initiative
  );
  const [territory, setTerritory] = useState(defaults[dataType].territory);
  const [analysis, setAnalysis] = useState(defaults[dataType].analysis);
  const [paramsLoaded, setParamsLoaded] = useState(false);
  const [hasntFirstTerritory, setHasntFirstTerritory] = useState(false);
  const [groupingOptions, hierarchy, grouping, setGrouping, forceReset] =
    useGrouping(
      territory,
      dataType,
      paramsLoaded,
      {},
      initiative,
      hasntFirstTerritory
    );

  const [timeOptions, setTimeOptions] = useState();
  const [timeMode, setTimeMode] = useState(defaults[dataType].timeMode);
  const [initialTime, setInitialTime] = useState(
    defaults[dataType].initialTime
  );
  const [endTime, setEndTime] = useState(defaults[dataType].endTime);
  const [filtersUrlLoaded, setFiltersUrlLoaded] = useState(false);
  const years = useYears(initialTime, endTime);
  const months = useMonths(initialTime, endTime);
  const [statisticsOpened, setStatisticsOpened] = useState(true);
  const [filtersDesktopOpened, setFiltersDesktopOpened] = useState(true);
  const [filtersWaterClassification, setFiltersWaterClassification] = useState({
    natural: true,
    anthropic: true,
    hydroelectric: true,
    mining: true,
    aquaculture: true,
  });
  const [initiativeOptions, setInitiativeOptions] = useState({});

  /**
   * Reset essential filtering options
   */
  function resetFiltering() {
    setTerritoriesOptions([]);
    setTerritory(initiativeOptions[initiative].defaultTerritory);
    forceReset();
  }

  useEffect(() => {
    const queryParams = new URLSearchParams(window.location.search);

    if (
      !queryParams.has("territoryCode") ||
      !queryParams.has("territoryType")
    ) {
      setHasntFirstTerritory(true);
    }
  }, []);

  useEffect(() => {
    if (hasntFirstTerritory && initiativeOptions[params.initiative]) {
      setTerritory(initiativeOptions[params.initiative].defaultTerritory);
    }
  }, [initiativeOptions, hasntFirstTerritory]);

  useEffect(() => {
    let isSubscribed = true;

    api
      .get(`/initiatives`, {
        params: { dataType },
      })
      .then(({ data }) => {
        if (isSubscribed) {
          const initiatives = {};

          data.forEach((initiativeAux) => {
            const translation = `dashboard.filters.initiatives.options.${initiativeAux.type}`;
            initiatives[initiativeAux.type] = {
              key: initiativeAux.type,
              translation,
              defaultTerritory: initiativeAux.defaultTerritory,
            };

            i18next.addResource(
              "pt",
              "translation",
              translation,
              initiativeAux.namePt
            );
            i18next.addResource(
              "en",
              "translation",
              translation,
              initiativeAux.nameEn
            );
            i18next.addResource(
              "es",
              "translation",
              translation,
              initiativeAux.nameEs
            );
          });

          if (!initiatives[initiative]) {
            window.location.href = `${defaults[dataType].initiative}`;
            return;
          }

          if (!paramsLoaded) {
            setTerritory(initiatives[params.initiative].defaultTerritory);
          }

          setInitiativeOptions(initiatives);
        }
      });

    return () => {
      isSubscribed = false;
    };
  }, [dataType]);

  const analysisOptions = useMemo(() => {
    if (initiativeOptions) {
      if (options[dataType].analysis[initiative]) {
        return options[dataType].analysis[initiative];
      }

      return options[dataType].analysis.rest;
    }

    return options[dataType].analysis;
  }, [dataType, initiative, initiativeOptions]);

  useEffect(() => {
    if (initiativeOptions && options[dataType].analysis[initiative]) {
      if (!options[dataType].analysis[initiative][analysis]) {
        setAnalysis(defaults[dataType].analysis);
      }
    } else if (
      initiativeOptions &&
      !options[dataType].analysis.rest[analysis]
    ) {
      setAnalysis(defaults[dataType].analysis);
    }
  }, [dataType, initiative, initiativeOptions]);

  useEffect(() => {
    if (paramsLoaded) return () => {};

    let isSubscribed = true;

    api
      .get(
        `/${analysisOptions[analysis].apiName}/dates/${territory.type}/${territory.code}`,
        {
          params: { dataType },
        }
      )
      .then(({ data }) => {
        if (isSubscribed) {
          setTimeOptions(data);

          if (
            timeMode === "annual" &&
            data.years.includes(initialTime) &&
            data.years.includes(endTime)
          ) {
            return;
          }

          if (
            timeMode === "monthly" &&
            data.months.includes(initialTime) &&
            data.months.includes(endTime)
          ) {
            return;
          }

          if (data.years.length > 0) {
            if (analysis === analysisOptions.surface?.key) {
              setInitialTime(data.years[0].toString());
              setEndTime(data.years[data.years.length - 1].toString());
              setTimeMode("annual");
            } else if (analysis === analysisOptions.transition?.key) {
              if (initialTime.includes("-")) {
                setInitialTime(data.years[0].toString());
                setEndTime(data.years[data.years.length - 1].toString());
                setTimeMode("annual");
              } else {
                setInitialTime(data.years[0].toString());
                setEndTime(data.years[timeOptions.years.length - 1].toString());
                setTimeMode("annual");
              }
            } else if (analysis === analysisOptions.trend?.key) {
              if (initialTime.includes("-")) {
                setInitialTime(data.years[0].toString());
                setEndTime(data.years[data.years.length - 1].toString());
              } else {
                setInitialTime(data.years[0].toString());
                setEndTime(data.years[data.years.length - 1].toString());
                setTimeMode("annual");
              }
            } else if (analysis === analysisOptions.waterClassification?.key) {
              setInitialTime(data.years[data.years.length - 1].toString());
              setEndTime(data.years[data.years.length - 1].toString());
              setTimeMode("annual");
            }
          }
        }
      });

    return () => {
      isSubscribed = false;
    };
  }, [analysis, territory, paramsLoaded]);

  /**
   * This useEffect adds territory types translations.
   */
  useEffect(() => {
    let isSubscribed = true;

    api.get("/territories/translations").then(({ data }) => {
      if (isSubscribed) {
        Object.keys(data).forEach((keys) => {
          i18next.addResource(
            "pt",
            "translation",
            `dashboard.filters.territory.type.${keys}`,
            data[keys].pt
          );
          i18next.addResource(
            "en",
            "translation",
            `dashboard.filters.territory.type.${keys}`,
            data[keys].en
          );
          i18next.addResource(
            "es",
            "translation",
            `dashboard.filters.territory.type.${keys}`,
            data[keys].es
          );
        });
      }
    });

    return () => {
      isSubscribed = false;
    };
  }, []);

  /**
   * This function handle the url address according to the current filter params.
   */
  useEffect(() => {
    if (paramsLoaded) {
      handleFiltersRoute(
        dataType,
        initiative,
        territory,
        grouping,
        analysis,
        initialTime,
        endTime
      );
      setFiltersUrlLoaded(true);
    }
  }, [paramsLoaded, territory, grouping, analysis, initialTime, endTime]);

  /**
   * This useEffect will check if the filters params were passed correctyl.
   */
  useEffect(() => {
    if (
      paramsLoaded ||
      !groupingOptions ||
      Object.keys(groupingOptions).length === 0 ||
      !timeOptions
    ) {
      return () => {};
    }

    let isSubscribed = true;

    const queryParams = new URLSearchParams(window.location.search);

    let territoryTypeQuery = territory.type;
    let territoryCodeQuery = territory.code;

    if (queryParams.has("territoryType") && queryParams.has("territoryCode")) {
      territoryTypeQuery = queryParams.get("territoryType");
      territoryCodeQuery = queryParams.get("territoryCode");
    }

    let groupingQuery = grouping;

    if (queryParams.has("grouping")) {
      if (groupingOptions[queryParams.get("grouping")]) {
        groupingQuery = queryParams.get("grouping");
      }
    }

    let analysisQuery = analysis;

    if (queryParams.has("analysis")) {
      if (analysisOptions[queryParams.get("analysis")]) {
        analysisQuery = queryParams.get("analysis");
      }
    }

    let initialTimeQuery = initialTime;
    let endTimeQuery = endTime;

    if (queryParams.has("initialTime") && queryParams.has("endTime")) {
      /* Monthly date */
      if (
        queryParams.get("initialTime").includes("-") &&
        queryParams.get("endTime").includes("-")
      ) {
        if (analysisQuery === analysisOptions.surface?.key) {
          if (
            timeOptions.months.includes(queryParams.get("initialTime")) &&
            timeOptions.months.includes(queryParams.get("endTime"))
          ) {
            initialTimeQuery = queryParams.get("initialTime");
            endTimeQuery = queryParams.get("endTime");
          }
        }
      } else if (
        /* Yearly date */
        !queryParams.get("endTime").includes("-") &&
        !queryParams.get("initialTime").includes("-")
      ) {
        if (analysisQuery === analysisOptions.transition?.key) {
          if (queryParams.get("initialTime") !== queryParams.get("endTime")) {
            initialTimeQuery = queryParams.get("initialTime");
            endTimeQuery = queryParams.get("endTime");
          }
        } else if (analysisQuery === analysisOptions.trend?.key) {
          if (
            queryParams.get("endTime") - queryParams.get("initialTime") >=
            4
          ) {
            initialTimeQuery = queryParams.get("initialTime");
            endTimeQuery = queryParams.get("endTime");
          }
        } else if (analysisQuery === analysisOptions.waterClassification?.key) {
          if (queryParams.get("endTime") === queryParams.get("initialTime")) {
            initialTimeQuery = queryParams.get("initialTime");
            endTimeQuery = queryParams.get("endTime");
          }
        } else if (
          queryParams.get("endTime") >= queryParams.get("initialTime")
        ) {
          initialTimeQuery = queryParams.get("initialTime");
          endTimeQuery = queryParams.get("endTime");
        }
      }
    }

    api
      .get(`/territories/search/${territoryCodeQuery}/${territoryTypeQuery}`, {
        params: {
          dataType,
          initiative,
        },
      })
      .then(({ data }) => {
        if (isSubscribed) {
          if (data.code !== undefined) {
            if (!hasntFirstTerritory) {
              setTerritory(data);
              if (groupingQuery) {
                setGrouping(groupingQuery);
              }
            }
          }
        }
      })
      .finally(() => {
        setAnalysis(analysisQuery);
        setInitialTime(initialTimeQuery);
        setEndTime(endTimeQuery);
        setTimeMode(initialTimeQuery.includes("-") ? "monthly" : "annual");
        setParamsLoaded(true);
      });

    return () => {
      isSubscribed = false;
    };
  }, [groupingOptions, paramsLoaded, timeOptions]);

  function handleInitiative(newInitiative) {
    setInitiative(newInitiative);
    setTerritoriesOptions([]);
    setTerritory(initiativeOptions[newInitiative].defaultTerritory);
    forceReset();
  }

  return (
    <FiltersContext.Provider
      value={{
        options: {
          territoriesOptions,
          analysisOptions,
          groupingOptions,
          timeOptions,
          initiativeOptions,
        },
        values: {
          dataType,
          territory,
          analysis,
          grouping,
          timeMode,
          initialTime,
          endTime,
          years,
          months,
          hierarchy,
          statisticsOpened,
          filtersDesktopOpened,
          filtersWaterClassification,
          initiative,
        },
        setters: {
          setAnalysis,
          setTerritory,
          setTerritoriesOptions,
          setGrouping,
          setTimeMode,
          setInitialTime,
          setEndTime,
          setStatisticsOpened,
          setFiltersDesktopOpened,
          setFiltersWaterClassification,
          resetFiltering,
          handleInitiative,
        },
        loading: {
          filtersUrlLoaded,
          paramsLoaded,
        },
      }}
    >
      {children}
    </FiltersContext.Provider>
  );
}

export default FiltersContext;
