/* eslint-disable no-unused-vars */
import React, { useContext, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { Button } from "@material-ui/core";
import { useTheme } from "react-jss";
import PropTypes from "prop-types";
import CustomTypography from "../../../CustomTypography";
import useStyles from "./styles";
import SvgClose from "../../../Icons/Close";
import MobileMenu from "../../../MobileMenu";
import OptionList from "./OptionList";
import FiltersContext from "../../../../contexts/filtering";
import PeriodSelector from "./PeriodSelector";
import useGrouping from "../../../../hooks/grouping";
import { defaults, options } from "../../../../constants/general";

/**
 * This functional component renders the mobile filtering container.
 * @returns A wrapper that contains mobile filters.
 */
function MobileBar({ handleMobileOpen }) {
  MobileBar.propTypes = {
    handleMobileOpen: PropTypes.func.isRequired,
  };

  const classes = useStyles();
  const { t } = useTranslation();
  const {
    options: {
      analysisOptions,
      timeOptions,
      initiativeOptions,
      groupingOptions,
    },
    values: {
      analysis,
      initialTime,
      endTime,
      timeMode,
      initiative,
      territory,
      dataType,
    },
    setters: {
      setAnalysis,
      setGrouping,
      setInitialTime,
      setEndTime,
      setTimeMode,
      handleInitiative,
    },
  } = useContext(FiltersContext);
  const theme = useTheme();

  const [analysisSelection, setAnalysisSelection] = useState(analysis);
  const [initiativeSelection, setInitiativeSelection] = useState(initiative);
  const [periodSelectorOpened, setPeriodSelectorOpened] = useState(false);
  const [initialTimeSelection, setInitialTimeSelection] = useState(initialTime);
  const [endTimeSelection, setEndTimeSelection] = useState(endTime);
  const [timeModeSelection, setTimeModeSelection] = useState(timeMode);
  const [analysisOptionsSelection, setAnalysisOptionsSelection] =
    useState(analysisOptions);
  const [opened, setOpened] = useState(false);
  const [
    groupingOptionsSelection,
    hierarchy,
    groupingSelection,
    setGroupingSelection,
    forceReset,
  ] = useGrouping(
    initiativeOptions[initiativeSelection].defaultTerritory,
    dataType,
    true,
    groupingOptions,
    initiativeSelection,
    true
  );

  /**
   * Applies the current filter selection.
   */
  function handleApplication() {
    setAnalysis(analysisSelection);
    setGrouping(groupingSelection);
    setInitialTime(initialTimeSelection);
    setEndTime(endTimeSelection);
    setTimeMode(timeModeSelection);
    handleInitiative(initiativeSelection);
    handleMobileOpen();
  }

  useEffect(() => {
    if (opened) {
      setTimeModeSelection("annual");

      if (analysisSelection === analysisOptions.surface?.key) {
        setInitialTimeSelection(timeOptions.years[0].toString());
        setEndTimeSelection(
          timeOptions.years[timeOptions.years.length - 1].toString()
        );
      } else if (analysisSelection === analysisOptions.transition?.key) {
        setInitialTimeSelection(timeOptions.years[0].toString());
        setEndTimeSelection(
          timeOptions.years[timeOptions.years.length - 1].toString()
        );
      } else if (analysisSelection === analysisOptions.trend?.key) {
        setInitialTimeSelection(timeOptions.years[0].toString());
        setEndTimeSelection(
          timeOptions.years[timeOptions.years.length - 1].toString()
        );
      } else if (
        analysisSelection === analysisOptions.waterClassification?.key
      ) {
        setInitialTimeSelection(
          timeOptions.years[timeOptions.years.length - 1].toString()
        );
        setEndTimeSelection(
          timeOptions.years[timeOptions.years.length - 1].toString()
        );
      }
    } else {
      setOpened(true);
    }
  }, [analysisSelection]);

  useEffect(() => {
    if (initiativeOptions) {
      if (options[dataType].analysis[initiativeSelection]) {
        setAnalysisOptionsSelection(
          options[dataType].analysis[initiativeSelection]
        );

        if (
          !options[dataType].analysis[initiativeSelection][analysisSelection]
        ) {
          setAnalysisSelection(defaults[dataType].analysis);
        }
      } else {
        setAnalysisOptionsSelection(options[dataType].analysis.rest);

        if (!options[dataType].analysis.rest) {
          setAnalysisSelection(defaults[dataType].analysis);
        }
      }
    }
  }, [dataType, initiativeSelection]);

  return (
    <MobileMenu
      header={
        <div className={classes.header}>
          <CustomTypography variant="contrast" weight="bold">
            {t("dashboard.filters.mobile.title")}
          </CustomTypography>
          <Button className={classes.closeButton} onClick={handleMobileOpen}>
            <SvgClose className={classes.closeIcon} />
          </Button>
        </div>
      }
    >
      <div className={classes.buttonContainer}>
        {!periodSelectorOpened && (
          <Button
            className={classes.confirmButton}
            variant="contained"
            disableElevation
            color="primary"
            onClick={handleApplication}
          >
            <CustomTypography variant="contrast" color={theme.white}>
              {t("dashboard.filters.mobile.apply")}
            </CustomTypography>
          </Button>
        )}
      </div>
      <div className={classes.content}>
        <PeriodSelector
          setOpened={setPeriodSelectorOpened}
          initialTime={initialTimeSelection}
          endTime={endTimeSelection}
          setInitialTime={setInitialTimeSelection}
          setEndTime={setEndTimeSelection}
          timeMode={timeModeSelection}
          setTimeMode={setTimeModeSelection}
          analysis={analysisSelection}
        />
        {!periodSelectorOpened && (
          <>
            {/* <OptionList
              title={t("dashboard.filters.initiatives.title")}
              options={initiativeOptions}
              value={initiativeSelection}
              setValue={setInitiativeSelection}
              sort
            /> */}
            <OptionList
              title={t("dashboard.filters.territorialGrouping.title")}
              options={groupingOptionsSelection}
              value={groupingSelection}
              setValue={setGroupingSelection}
              sort
            />
            <OptionList
              title={t("dashboard.filters.analysis.title")}
              options={analysisOptionsSelection}
              value={analysisSelection}
              setValue={setAnalysisSelection}
            />
          </>
        )}
      </div>
    </MobileMenu>
  );
}

export default MobileBar;
