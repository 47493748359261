/* eslint-disable no-unused-vars */
import React, { createContext } from "react";
import PropTypes from "prop-types";
import { useHistory, useParams } from "react-router-dom";
import { defaults } from "../constants/general";

const LocationContext = createContext({});

/**
 * The LoationProvider is a context to generate and process the route params.
 * */
export function LocationProvider({ dataType, children }) {
  LocationProvider.propTypes = {
    children: PropTypes.oneOfType([
      PropTypes.arrayOf(PropTypes.node),
      PropTypes.node,
    ]).isRequired,
    dataType: PropTypes.string.isRequired,
  };

  const history = useHistory();
  const params = useParams();

  /**
   * Handles viewport route changing.
   * @param {New map latitude value} lat
   * @param {New map longitude value} lng
   * @param {New map zoom value} zoom
   */
  function handleMapRoute(route, lat, lng, zoom) {
    const queryParams = new URLSearchParams(window.location.search);

    /* queryParams.set("lat", lat);
    queryParams.set("lng", lng);
    queryParams.set("zoom", zoom); */
    if (queryParams.has("territoryType")) {
      queryParams.set("territoryType", queryParams.get("territoryType"));
    }
    if (queryParams.has("territoryCode")) {
      queryParams.set("territoryCode", queryParams.get("territoryCode"));
    }
    if (queryParams.has("grouping")) {
      queryParams.set("grouping", queryParams.get("grouping"));
    }
    if (queryParams.has("analysis")) {
      queryParams.set("analysis", queryParams.get("analysis"));
    }
    if (queryParams.has("initialTime")) {
      queryParams.set("initialTime", queryParams.get("initialTime"));
    }
    if (queryParams.has("endTime")) {
      queryParams.set("endTime", queryParams.get("endTime"));
    }

    const initiative = params.initiative || defaults[dataType].initiative;
    history.replace(`/${route}/${initiative}?${queryParams.toString()}`);
  }

  /**
   * Handles the filtering route changing.
   * @param {New initiative value} initiative
   * @param {New territory value} territory
   * @param {New grouping value} grouping
   * @param {New analysis value} analysis
   * @param {New initial time value} initialTime
   * @param {New end time value} endTime
   */
  function handleFiltersRoute(
    route,
    initiative,
    territory,
    grouping,
    analysis,
    initialTime,
    endTime
  ) {
    const queryParams = new URLSearchParams(window.location.search);

    queryParams.set("territoryType", territory.type);
    queryParams.set("territoryCode", territory.code);
    queryParams.set("grouping", grouping);
    queryParams.set("analysis", analysis);
    queryParams.set("initialTime", initialTime);
    queryParams.set("endTime", endTime);
    /* if (queryParams.has("lat")) {
      queryParams.set("lat", queryParams.get("lat"));
    }
    if (queryParams.has("lng")) {
      queryParams.set("lng", queryParams.get("lng"));
    }
    if (queryParams.has("zoom")) {
      queryParams.set("zoom", queryParams.get("zoom"));
    } */

    const finalInitiative = initiative || defaults[dataType].initiative;
    history.replace(`/${route}/${finalInitiative}?${queryParams.toString()}`);
  }

  return (
    <LocationContext.Provider
      value={{
        handleMapRoute,
        handleFiltersRoute,
        dataType,
      }}
    >
      {children}
    </LocationContext.Provider>
  );
}

export default LocationContext;
