import React from "react";
import { BrowserRouter, Redirect, Switch } from "react-router-dom";
import HeaderRoute from "./components/Router/HeaderRoute";
import Water from "./pages/Water";
import Methodology from "./pages/Methodology";
import Glacier from "./pages/Glacier";
import { defaults } from "./constants/general";

/**
 * Does the platform routing.
 */
function Routes() {
  return (
    <BrowserRouter>
      <Switch>
        <HeaderRoute exact path="/water/:initiative" component={Water} />
        <HeaderRoute exact path="/glacier/:initiative" component={Glacier} />
        <HeaderRoute exact path="/methodology" component={Methodology} />
        <Redirect from="*" to={`/water/${defaults.water.initiative}`} />
      </Switch>
    </BrowserRouter>
  );
}

export default Routes;
