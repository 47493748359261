import React, { useContext, useEffect, useRef, useState } from "react";
import { Button, useMediaQuery } from "@material-ui/core";
import { useTranslation } from "react-i18next";
import SvgBack from "../../Icons/Back";
import SvgFilter from "../../Icons/Filter";
import useStyles from "./styles";
import FiltersContext from "../../../contexts/filtering";
import TerritoriesAutocomplete from "../AutoComplete";
import Selector from "../Selector";
import MobileBar from "./MobileBar";
import { breakpoints } from "../../../constants/constraints";
import SvgHouse from "../../Icons/House";
import MapContext from "../../../contexts/mapping";
import CustomTooltip from "../../CustomTooltip";
import CustomModal from "../Modal";

/**
 * This functional component renders the filtering container.
 * @returns A wrapper that contains filters.
 */
const FilteringContainer = React.forwardRef((props, ref) => {
  const classes = useStyles();
  const {
    values: { filtersDesktopOpened, timeMode /* initiative */ },
    setters: { setFiltersDesktopOpened, resetFiltering },
  } = useContext(FiltersContext);

  const [mobileOpen, setMobileOpen] = useState(false);
  const collapseRef = useRef();
  const wrapperRef = useRef();
  const { t } = useTranslation();
  const {
    options: {
      analysisOptions,
      groupingOptions,
      timeOptions,
      /* initiativeOptions, */
    },
    values: { analysis, grouping, initialTime },
    setters: {
      setAnalysis,
      setGrouping,
      setInitialTime,
      setEndTime,
      setTimeMode,
      /* handleInitiative */
    },
  } = useContext(FiltersContext);
  const { resetMapping } = useContext(MapContext);
  const smd = useMediaQuery(breakpoints.min.smd);
  const [betaMonthAlert, setBetaMonthAlert] = useState(false);

  /**
   * Closes the mobileOpen when the smd value is reached.
   */
  useEffect(() => {
    if (smd) {
      setMobileOpen(false);
    }
  }, [smd]);

  /**
   * This function switches the collapse bar.
   */
  function handleDesktopOpen() {
    setFiltersDesktopOpened(!filtersDesktopOpened);
  }

  /**
   * this function switches the filtering mobile bar.
   */
  function handleMobileOpen() {
    setMobileOpen(!mobileOpen);
  }

  /**
   * This function will do the correct transformations on selected date based on the new analysis.
   */
  function handleAnalysis(newAnalysis) {
    if (newAnalysis === analysisOptions.surface?.key) {
      setInitialTime(timeOptions.years[0].toString());
      setEndTime(timeOptions.years[timeOptions.years.length - 1].toString());
      setTimeMode("annual");
      setAnalysis(newAnalysis);
    } else if (newAnalysis === analysisOptions.transition?.key) {
      if (initialTime.includes("-")) {
        setInitialTime(timeOptions.years[0].toString());
        setEndTime(timeOptions.years[timeOptions.years.length - 1].toString());
        setTimeMode("annual");
        setAnalysis(newAnalysis);
      } else {
        /* setInitialTime(timeOptions.years[0]);
        setEndTime(timeOptions.years[timeOptions.years.length - 1]); */
        /* if (initialTime === endTime) {
          const initValue = parseInt(initialTime, 10);
          const endValue = parseInt(endTime, 10);

          if (endValue === timeOptions.years[timeOptions.years.length - 1]) {
            setInitialTime((initValue - 1).toString());
          } else {
            setEndTime((initValue + 1).toString());
          }
        } */

        setInitialTime(timeOptions.years[0].toString());
        setEndTime(timeOptions.years[timeOptions.years.length - 1].toString());
        setTimeMode("annual");
        setAnalysis(newAnalysis);
      }
    } else if (newAnalysis === analysisOptions.trend?.key) {
      if (initialTime.includes("-")) {
        setInitialTime(timeOptions.years[0].toString());
        setEndTime(timeOptions.years[timeOptions.years.length - 1].toString());
        setAnalysis(newAnalysis);
      } else {
        /* const initValue = parseInt(initialTime, 10);
        const endValue = parseInt(endTime, 10);

        if (endValue - initValue < 5) {
          if (endValue < timeOptions.years[timeOptions.years.length - 1] - 2) {
            setEndTime((initValue + 4).toString());
          } else {
            setInitialTime((endValue - 4).toString());
          }
        } */

        setInitialTime(timeOptions.years[0].toString());
        setEndTime(timeOptions.years[timeOptions.years.length - 1].toString());
        setTimeMode("annual");
        setAnalysis(newAnalysis);
      }
    } else if (newAnalysis === analysisOptions.waterClassification?.key) {
      setInitialTime(
        timeOptions.years[timeOptions.years.length - 1].toString()
      );
      setEndTime(timeOptions.years[timeOptions.years.length - 1].toString());
      setTimeMode("annual");
      setAnalysis(newAnalysis);
    } else {
      setAnalysis(newAnalysis);
    }
  }

  function resetPage() {
    resetFiltering();
    resetMapping();
  }

  useEffect(() => {
    if (timeMode === "monthly") {
      setBetaMonthAlert(true);
    }
  }, [timeMode]);

  return (
    <>
      <div
        className={classes.containerDesktop}
        ref={ref}
        style={{
          padding: filtersDesktopOpened
            ? "0px 14px 0px 14px"
            : "0px 0px 0px 14px",
        }}
      >
        <Button
          className={classes.backButton}
          onClick={handleDesktopOpen}
          style={{
            marginRight: 14,
            transition: "margin-right 0.2s ease",
          }}
        >
          <SvgBack
            className={classes.backIcon}
            style={{
              transform: filtersDesktopOpened
                ? "rotate(0deg)"
                : "rotate(180deg)",
            }}
          />
        </Button>
        <div
          ref={collapseRef}
          style={{ width: filtersDesktopOpened ? 706 : 0 }}
          className={classes.collapse}
        >
          <div ref={wrapperRef} className={classes.wrapper}>
            <CustomTooltip
              title={t("dashboard.filters.resetTooltip")}
              placement="bottom"
            >
              <Button
                className={classes.houseButton}
                onClick={resetPage}
                style={{ margin: "0px 10px 0px 0px", width: 20 }}
              >
                <SvgHouse className={classes.houseIcon} />
              </Button>
            </CustomTooltip>
            {/* <Selector
              title={t("dashboard.filters.initiatives.title")}
              value={initiative}
              setValue={handleInitiative}
              options={initiativeOptions}
              sort
            />
            <span style={{ width: 16 }} /> */}
            <TerritoriesAutocomplete />
            <span style={{ width: 16 }} />
            <Selector
              title={t("dashboard.filters.territorialGrouping.title")}
              value={grouping}
              setValue={setGrouping}
              options={groupingOptions}
              sort
            />
            <span style={{ width: 16 }} />
            <Selector
              title={t("dashboard.filters.analysis.title")}
              value={analysis}
              setValue={handleAnalysis}
              options={analysisOptions}
            />
          </div>
        </div>
      </div>
      <div className={classes.containerMobile}>
        <TerritoriesAutocomplete />
        <CustomTooltip
          title={t("dashboard.filters.resetTooltip")}
          placement="bottom"
        >
          <Button className={classes.resetButton} onClick={resetPage}>
            <SvgHouse className={classes.houseIcon} />
          </Button>
        </CustomTooltip>
        <CustomTooltip
          title={t("dashboard.filters.editTooltip")}
          placement="bottom"
        >
          <Button className={classes.filterButton} onClick={handleMobileOpen}>
            <SvgFilter className={classes.filterIcon} />
          </Button>
        </CustomTooltip>
        {mobileOpen && <MobileBar handleMobileOpen={handleMobileOpen} />}
        {betaMonthAlert && (
          <CustomModal
            close={() => setBetaMonthAlert(false)}
            buttonText="OK"
            title={t("dashboard.filters.timeline.monthlyAlertTitle")}
          >
            {t("dashboard.filters.timeline.monthlyAlertText")}
          </CustomModal>
        )}
      </div>
    </>
  );
});
export default FilteringContainer;
